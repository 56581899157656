import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/empty-layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { BiCalendar, BiTimeFive } from "react-icons/bi"
import { HiUserGroup } from "react-icons/hi"
import { BsPlusCircle } from "react-icons/bs";

type DataProps = {
    data: IndexQueryQuery
    location: Location
}

const initialFormData = Object.freeze({
    id: '',
    talking_points: [],
    code: "",
    email: ""
})

const MeetingPage: React.FC<PageProps<DataProps>> = ({ location }) => {

    const data = useStaticQuery(
        graphql`
          query {
            agenda_bg: file(relativePath: { eq: "hero-bg1.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            agenda_section2_bg: file(relativePath: { eq: "signup-bg.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 1400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            logo_hero: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
        `
    )
    const agenda_bg = getImage(data.agenda_bg)
    const agenda_section2_bg = getImage(data.agenda_section2_bg)
    const logo_hero = getImage(data.logo_hero)

    const agenda_section_bg = convertToBgImage(agenda_bg)
    const agenda2_section_bg = convertToBgImage(agenda_section2_bg)
    const params = new URLSearchParams(location.search);
    const [formData, setFormData] = useState(initialFormData);
    const [talkingPointFields, setTalkingPointFields] = useState([{}]);
    const [formStatus, setFormStatus] = useState("standby");
    const [meeting, setMeeting] = useState([]);
    const code = params.get('code');
    const email = params.get('email');

    const addTalkingPointField = (e)=>{
        e.preventDefault();
        setTalkingPointFields([...talkingPointFields, {}]);
    }

    const removeTalkingPointField = (index)=>{
        const rows = [...talkingPointFields];
        rows.splice(index, 1);
        setTalkingPointFields(rows);
    }

    const handleChangeTalkingPoint = (index, evnt)=>{  
        const { name, value } = evnt.target;
        const list = [...talkingPointFields];
        list[index] = value;
        setTalkingPointFields(list);
        //console.log(criteriaFields);
        setFormData({
            ...formData,
            talking_points: talkingPointFields
        });
    }

    const retrieveMeeting = async () => {
        const response = fetch('https://peerclub.virtualbears.ph/api/meeting/invitation?code='+code+'&email='+email, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
            "Access-Control-Allow-Origin" : "*",
            "Access-Control-Allow-Credentials" : true ,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        })
        .then((response) => {
        return response.json();
        })
        .then((events) => {
            return events.data;
        });
        return response;
    };

    useEffect(() => {
        const getMeeting = async () => {
            const seletedMeeting = await retrieveMeeting();
            //console.log(selectedPeers);
            setMeeting(seletedMeeting);
        };

        setFormData({
            ...formData,
            code: code,
            email: email,
        });

        getMeeting();
    }, []);

    async function submitForm(e) {
        e.preventDefault();
        setFormStatus("loading");
        if (formData.talking_points.length > 0) {
            const response = await fetch("https://peerclub.virtualbears.ph/api/meeting/invitation/join", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrer: "client",
                body: JSON.stringify(formData),
            });

            const json = await response.json();
            
            if (json.status == "Success") {
                window.location.href = "/thank-you-agenda";
                setFormStatus("standby");
            }  
        } else {
            alert("Please fill up all required fields!");
            setFormStatus("standby");
        }
        
    }
    
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
            <Layout>
                <Seo
                    title="Meeting"
                />
                <BackgroundImage
                    Tag="section"
                    className="agenda-bg"
                    {...agenda_section_bg}
                    preserveStackingContext
                >
                    {meeting ?
                        <section id="agenda-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center mb-5">
                                        <Link to="/">
                                        <GatsbyImage
                                            image={logo_hero}
                                            alt="Logo"
                                            className="logo-hero"
                                        />
                                        </Link>
                                        <h1 className="text-black">{meeting.agenda_name}</h1>
                                        <p className="text-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam lorem ipsum dolor.</p>
                                        <p className="text-black p-bold"><span>Knowledge Partner</span> : {meeting.knowledge_partner}</p>
                                    </div>
                                </div>
                                <div className="row bottom-row">
                                    <div className="col-lg-4">
                                        <BiCalendar/> <span className="col-text">{meeting.date}</span>
                                    </div>
                                    <div className="col-lg-4">
                                        <BiTimeFive/> <span className="col-text">8:00 PM - 10:00 PM</span>
                                    </div>
                                    <div className="col-lg-4">
                                        <HiUserGroup/> <span className="col-text">280 PARTICIPANTS</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <section id="agenda-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center mb-5">
                                        <Link to="/">
                                        <GatsbyImage
                                            image={logo_hero}
                                            alt="Logo"
                                            className="logo-hero"
                                        />
                                        </Link>
                                        <h1 className="text-black">NO DATA FOUND.</h1>
                                        <p className="text-black">No data found.</p>
                                        <p className="text-black p-bold"><span>Knowledge Partner</span> : No Data Found.</p>
                                    </div>
                                </div>
                                <div className="row bottom-row">
                                    <div className="col-lg-4">
                                        <BiCalendar/> <span className="col-text">APRIL 07, 2022</span>
                                    </div>
                                    <div className="col-lg-4">
                                        <BiTimeFive/> <span className="col-text">8:00 PM - 10:00 PM</span>
                                    </div>
                                    <div className="col-lg-4">
                                        <HiUserGroup/> <span className="col-text">280 PARTICIPANTS</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    
                </BackgroundImage>

                <BackgroundImage
                    Tag="section"
                    className="agenda-bg"
                    {...agenda2_section_bg}
                    preserveStackingContext
                >
                    <section id="agenda-section2-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="text-center text-white">TALKING POINTS</h2>
                                    <p className="text-center text-white">If you were attending at Round Table meeting with peers from the market you serve what woud you most like to talk about?</p>
                                    {talkingPointFields.map((data, index)=>{const {talking_point} = data;
                                        return(
                                            <>
                                                {(talkingPointFields.length > 0) ?
                                                    <>
                                                        
                                                        <div className="mb-0 form-group" key={index}>
                                                            <textarea
                                                                onChange={(evnt)=>handleChangeTalkingPoint(index, evnt)} 
                                                                name="talking_point" 
                                                                className="form-control mb-3"
                                                                value={talking_point}
                                                            ></textarea>
                                                        </div>
                                           
                                                    </>
                                                    :
                                                    null
                                                }
                                            </>
                                        )
                                    })}
                                    <div onClick={addTalkingPointField} className="add-more-container">
                                        <BsPlusCircle/> <span className="text-white">ADD MORE</span>
                                    </div>
                                    {formStatus != "standby" ?
                                        <button className="submit-btn">SENDING...</button>
                                        :
                                        <button className="submit-btn" onClick={submitForm}>SUBMIT</button>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </BackgroundImage>
            </Layout>
        </>
    )
}

export default MeetingPage
