/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import { motion } from "framer-motion"
 
 import Header from "../components/navigation/header"
 import Footer from "../components/Footer"
 import "./layout.css"
 
 const EmptyLayout = ({ children }) => {
   return (
     <>
       
         <motion.main
           /*initial={{ opacity: 0, y: -50 }}
           animate={{ opacity: 1, y: 0 }}
           exit={{ opacity: 0, y: 50 }}
           transition={{
             type: "spring",
             mass: 0.35,
             stiffness: 75,
             duration: 0.3
           }}   */     
         >
           {children}
 
         </motion.main>
     </>
   )
 }
 
 EmptyLayout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default EmptyLayout 